import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Input, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { fireToast } from "common/Toster";
import moment from "moment";
import { saveAs } from "file-saver";
import BASE_URL from "routes/apiRoutes";
import { get } from "helpers/api_helper";
import { FaCopy } from "react-icons/fa";

function Staking() {
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [error, setError] = useState();
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState("");

  useEffect(() => {
    fetchData();
  }, [currentPage, search]);

  const fetchData = async () => {
    try {
      const response = await get(`/admin/staking/refferal?pageNo=${currentPage}&pageSize=${itemsPerPage}&search=${search}`);
      if (response.success) {
        setUserData(response?.data);
        setPageNo(response?.meta);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const exportCsv = async () => {
    try {
      const response = await get(`/admin/staking/refferal?&exportCsv=true`);
      if (response.success) {
        // Save the blob as a CSV file
        saveAs(new Blob([response?.data]), "exported_data.csv");
        fireToast('success', "Data downloaded successfully !");
      } else {
        fireToast('error', response?.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    fireToast("success", "Wallet address copied to clipboard!");
  };

  return (
    <div className="Users_list container-fluid mt-5 py-5">
      <div className="row">
        <div className="userinfoSearch d-flex justify-content-between col">
          <h1>Staking/Referral</h1>
          <Input
            type="text"
            value={search}
            className="col-3 ps-3 w-25 me-3 mb-3"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search by Wallet Address"
          />
        </div>

        <div className="table_data" style={{ overflowX: "auto" }}>
          <Table hover responsive striped className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>User Id</th>
                <th>Amount</th>
                <th style={{ width: "15%" }}>Wallet Address</th>
                <th>Generation</th>
                <th>Amount Staked</th>
                <th>Amount Burned</th>
                <th>Open Balance</th>
                <th>Net Amount</th>
                <th>Amount Rewarded</th>
                <th>Amount Commissioned</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((userinfo) => (
                <tr key={userinfo?.id} className={userinfo[`user.status`] === "inactive" ? "text-danger" : ""}>
                  <td>{userinfo?.id}</td>
                  <td>{userinfo?.user_id}</td>
                  <td>{userinfo?.amount}</td>
                  <td className="text-truncate" style={{ maxWidth: "200px" }}>
                    {/* <span>
                      {userinfo['user.wallet_address'].substring(0, 15)}...
                    </span> */}
                    <span>
                      {userinfo?.user?.wallet_address?.substring(0, 7)}...
                      {userinfo?.user?.wallet_address?.substring(
                        userinfo?.user?.wallet_address?.length - 7
                      )}
                    </span>
                    <FaCopy
                      className="ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => copyToClipboard(userinfo?.user?.wallet_address)}
                    />
                  </td>
                  <td>{userinfo?.user?.generation_level}</td>
                  <td>{userinfo?.user.amount_staked}</td>
                  <td>{userinfo?.user?.amount_burned}</td>
                  <td>{userinfo?.user?.open_balance}</td>
                  <td>{userinfo?.user?.net_amount}</td>
                  <td>{userinfo?.user?.amount_rewarded}</td>
                  <td>{userinfo?.user?.amount_commissioned}</td>
                  <td>
                    {moment(userinfo?.created_on).format("MMMM Do YYYY, h:mm:ss a")}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="text-end me-5">
        <button
          onClick={() => exportCsv()}
          className="btn btn-primary rounded-circle"
        >
          <i className="fas fa-download"></i>
        </button>
      </div>

      <div className="pagination justify-content-center my-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-left"></i>
        </button>
        <h6 className="mx-2 my-auto">{currentPage}</h6>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === pageNo?.pages}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  );
}

export default Staking;
