import { fireToast } from "common/Toster";
import { post } from "helpers/api_helper";
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Input } from "reactstrap";

function Deduction({ user, fetchData }) {
  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState("");
  const [comment, setComment] = useState("");
  
  const toggle = () => setModal(!modal);

  const handleDeduction = async () => {
    // Ensure amount and comment are not empty
    if (!amount || !comment) {
      alert("Amount and comment are required.");
      return;
    }

    // Show confirmation dialog
    const confirmDeduction = window.confirm("Are you sure you want to proceed with the deduction?");
    
    if (confirmDeduction) {
      try {
        const response = await post("/admin/deduct-balance", {
          amount,
          comment,
          user_id: user?.id,
        });

        if (response.success) {
          fireToast("success", response?.message);
          fetchData()
        } else {
          fireToast("error", response.message);
        }
      } catch (e) {
        fireToast("error", e.message || "An error occurred. Please try again.");
      }
    }
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}>
        Deduction
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Balance Deduction</ModalHeader>
        <ModalBody>
          <div>
            <h5>
              <strong>User: </strong>
              {user?.wallet_address}
            </h5>
            <div className="d-flex justify-content-between text-center mt-3">
              <div className="p-2">
                <strong>Commissioned</strong>
                <p>{user?.amount_commissioned}</p>
              </div>
              <div className="p-2">
                <strong>Rewarded</strong>
                <p>{user?.amount_rewarded}</p>
              </div>
              <div className="p-2">
                <strong>Staked</strong>
                <p>{user?.amount_staked}</p>
              </div>
              <div className="p-2">
                <strong>Net Amount</strong>
                <p>{user?.net_amount}</p>
              </div>
              <div className="p-2">
                <strong>Open Bal</strong>
                <p>{user?.open_balance}</p>
              </div>
            </div>
            <Input
              type="number"
              placeholder="Enter amount to deduct"
              className="mt-3"
              required
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <Input
              type="textarea"
              placeholder="Enter comment"
              className="mt-3"
              required
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div className="d-flex justify-content-evenly mt-3">
              <Button color="danger" className="w-25" onClick={toggle}>
                Cancel
              </Button>
              <Button color="success" className="w-25" onClick={handleDeduction}>
                Deduct
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Deduction;
