import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { get } from "helpers/api_helper";
import moment from "moment";

function UserInfo({ UserId }) {
  const [modal, setModal] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const toggle = () => setModal(!modal);

  const fetchData = async (page) => {
    try {
      const response = await get(`/admin/${UserId}/transactions?page=${page}`);
      if (response.success) {
        setUserInfo(response.data);
        setCurrentPage(parseInt(response.meta.pageNo, 10)); // Ensure pageNo is an integer
        setTotalPages(parseInt(response.meta.pages, 10)); // Ensure pages is an integer
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (modal) {
      fetchData(currentPage);
    }
  }, [modal, currentPage]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages && page !== currentPage) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    if (modal) {
      fetchData(currentPage);
    }
  }, [currentPage, modal]);

  return (
    <div>
      <Button className="btn-success" onClick={toggle}>
        View
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>User Transaction</ModalHeader>
        <ModalBody>
          {userInfo.length > 0 ? (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Comments</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>
                  {userInfo.map((transaction, index) => (
                    <tr key={transaction.id}>
                      <td>{index + 1}</td>
                      <td>{transaction?.type?.toUpperCase()}</td>
                      <td>{transaction?.amount}</td>
                      <td>{transaction?.comments}</td>
                      <td>{moment(transaction?.created_on).format("LL")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {totalPages > 1 && (
                <Pagination className="d-flex justify-content-center">
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => handlePageClick(currentPage - 1)}
                    />
                  </PaginationItem>
                  {/* {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (page) => (
                      <PaginationItem
                        key={page}
                        active={page === currentPage}
                      >
                        <PaginationLink onClick={() => handlePageClick(page)}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    )
                  )} */}
                  <PaginationLink>{currentPage}</PaginationLink>
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => handlePageClick(currentPage + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              )}
            </>
          ) : (
            <div className="text-center">No data available</div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default UserInfo;
