import React, { useEffect, useState } from "react"
import axios from "axios"
import { Button, Input, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { fireToast } from "common/Toster"
import moment from "moment"
import { saveAs } from "file-saver"
import BASE_URL from "routes/apiRoutes"
import { get, put } from "helpers/api_helper"
import UserInfo from "./UserInfo"
import Deduction from "./Deduction"
import { FaCopy } from "react-icons/fa"

function ManageUser() {
  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(20)
  const [error, setError] = useState()
  const [search, setSearch] = useState("")
  const [pageNo, setPageNo] = useState("")

  useEffect(() => {
    fetchData()
  }, [currentPage, search])

  const localData = JSON.parse(localStorage.getItem("authUser"))
  const token = localData?.token

  const fetchData = async () => {
    try {
      const response = await get(
        `/admin/get_users?pageNo=${currentPage}&pageSize=${itemsPerPage}&search=${search}`
      )
      if (response.success) {
        setUserData(response?.data)
        setPageNo(response?.meta)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const exportCsv = async () => {
    try {
      const response = await get(`/admin/get_users?&exportCsv=true`)
      if (response.success) {
        // Save the blob as a CSV file
        saveAs(new Blob([response?.data]), "exported_data.csv")
        fireToast("success", "Data downloaded successfully !")
      } else {
        fireToast("error", response.message)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const activeInactiveUser = async (userId, currentStatus) => {
    console.log("Current Status", userId)
    console.log("Current Status:", currentStatus)
    if (userId) {
      const confirmMessage =
        currentStatus === "active" ? "deactivate" : "activate"

      if (
        window.confirm(`Are you sure? You want to ${confirmMessage} this user.`)
      ) {
        try {
          const response = await put("/admin/update_user", {
            user_id: userId,
            status: currentStatus === "active" ? "inactive" : "active",
          })

          if (response.success) {
            fireToast("success", response?.message)
            fetchData()
          } else {
            fireToast("error", response.message)
          }
        } catch (e) {
          fireToast("error", "Error updating user status")
        }
      }
    } else {
      setError(
        "Something went wrong. User ID is empty. Refresh the page and try again."
      )
    }
  }

  const copyToClipboard = address => {
    navigator.clipboard.writeText(address)
    fireToast("success", "Wallet address copied to clipboard!")
  }

  return (
    <div className="Users_list container-fluid mt-5 py-5">
      <div className="row">
        <div className="userinfoSearch d-flex justify-content-between col">
          <h1>All User Info Page</h1>
          <Input
            type="text"
            value={search}
            className="col-3 ps-3 w-25 me-3 mb-3"
            onChange={e => setSearch(e.target.value)}
            placeholder="Search by Wallet Address"
          />
        </div>

        <div className="table_data">
          <Table
            hover
            striped
            responsive
            className="table table-bordered table-striped"
          >
            <thead>
              <tr className="text-center">
                <th>ID</th>
                <th>Wallet Address</th>
                <th>Generation Level</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Action</th>
                <th>User Info</th>
                <th>Deduction</th>
              </tr>
            </thead>
            <tbody>
              {userData.map(user => (
                <tr
                  key={user?.id}
                  className={
                    user?.status === "inactive"
                      ? "text-danger text-center"
                      : "text-center"
                  }
                >
                  <td>{user?.id}</td>
                  <td className="text-truncate" style={{ maxWidth: "250px" }}>
                    {/* <span>{user?.wallet_address.substring(0, 15)}...</span> */}
                    <span>
                      {user?.wallet_address?.substring(0, 6)}...
                      {user?.wallet_address?.substring(
                        user?.wallet_address?.length - 6
                      )}
                    </span>
                    <FaCopy
                      className="ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => copyToClipboard(user?.wallet_address)}
                    />
                  </td>
                  <td>{user?.generation_level}</td>
                  <td>{user?.status}</td>
                  <td>
                    {moment(user?.created_on).format("MMMM Do YYYY, h:mm:ss a")}
                  </td>
                  <td>
                    <Button
                      onClick={() => activeInactiveUser(user.id, user.status)}
                      className={
                        user.status === "active" ? "btn-success" : "btn-danger"
                      }
                    >
                      {user.status === "active" ? "Active" : "Inactive"}
                    </Button>
                  </td>
                  <td>
                    <UserInfo UserId={user?.id} />
                  </td>
                  <td>
                    <Deduction user={user} fetchData={()=>{fetchData()}}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="text-end me-5">
        <button
          onClick={() => exportCsv()}
          className="btn btn-primary rounded-circle"
        >
          <i className="fas fa-download"></i>
        </button>
      </div>

      <div className="pagination justify-content-center my-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-left"></i>
        </button>
        <h6 className="mx-2 my-auto">{currentPage}</h6>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === pageNo?.pages}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  )
}

export default ManageUser
