import React, { useEffect, useState } from "react"
import axios from "axios"
import { Button, Input, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { fireToast } from "common/Toster"
import moment from "moment"
import { saveAs } from "file-saver"
import BASE_URL from "routes/apiRoutes"
import { get } from "helpers/api_helper"
import { FaCopy } from "react-icons/fa"

function Withdraw() {
  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(20)
  const [error, setError] = useState()
  const [search, setSearch] = useState("")
  const [pageNo, setPageNo] = useState("")
  const [csv, setCsv] = useState(false)

  useEffect(() => {
    fetchData()
  }, [currentPage, search, csv])

  const fetchData = async () => {
    try {
      const response = await get(
        `/admin/get_withdraw_history?pageNo=${currentPage}&pageSize=${itemsPerPage}&search=${search}`
      )
      if (response?.success) {
        setUserData(response?.data)
        setPageNo(response?.meta)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const exportCsv = async () => {
    try {
      const response = await get(`/admin/get_withdraw_history?&exportCsv=true`)
      if (response?.success) {
        // Save the blob as a CSV file
        saveAs(new Blob([response?.data]), "exported_data.csv")
        fireToast("success", "Data downloaded successfully !")
      } else {
        fireToast("error", response.message)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
    fireToast("success", "Wallet address copied to clipboard!")
  }

  return (
    <div className="Users_list container-fluid mt-5 py-5">
      <div className="row">
        <div className="userinfoSearch d-flex justify-content-between col">
          <h1>Withdraw</h1>
          <Input
            type="text"
            value={search}
            className="col-3 ps-3 w-25 me-3 mb-3"
            onChange={e => setSearch(e.target.value)}
            placeholder="Search by Wallet Address"
          />
        </div>

        <div className="table_data" style={{ overflowX: "auto" }}>
          <Table
            responsive
            hover
            striped
            className="table table-bordered table-striped"
          >
            <thead>
              <tr className="text-center">
                <th style={{ width: "5%" }}>S No.</th>
                <th style={{ width: "10%" }}>User ID</th>
                <th style={{ width: "10%" }}>Amount</th>
                <th style={{ width: "20%" }}>Wallet Address</th>
                <th style={{ width: "20%" }}>Transaction Hash</th>
                <th style={{ width: "20%" }}>Withdrawn Date</th>
              </tr>
            </thead>
            <tbody>
              {userData.map(item => (
                <tr
                  key={item?.id}
                  className={
                    item[`user.is_deleted`] === 1
                      ? "text-danger text-center"
                      : "text-center"
                  }
                >
                  <td>{item?.id}</td>
                  <td>{item?.user_id}</td>
                  <td>{item?.amount}</td>
                  <td className="text-truncate" style={{ maxWidth: "250px" }}>
                    {/* <span> */}
                      {/* {item[`user.wallet_address`].substring(0, 15)}... */}
                      <span>
                      {item?.wallet_address?.substring(0, 7)}...
                      {item?.wallet_address?.substring(
                        item?.wallet_address?.length - 7
                      )}
                    {/* </span> */}
                    </span>
                    <FaCopy
                      className="ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        copyToClipboard(item[`user.wallet_address`])
                      }
                    />
                  </td>
                  <td className="text-truncate" style={{ maxWidth: "250px" }}>
                    {/* <span>{item?.tx_hash.substring(0, 15)}...</span> */}
                    <span>
                      {item?.tx_hash?.substring(0, 7)}...
                      {item?.tx_hash?.substring(
                        item?.tx_hash?.length - 7
                      )}
                    </span>
                    <FaCopy
                      className="ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => copyToClipboard(item?.tx_hash)}
                    />
                  </td>
                  <td>
                    {moment(item?.created_on).format("MMMM Do YYYY, h:mm:ss a")}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="text-end me-5">
        <button
          onClick={() => exportCsv()}
          className="btn btn-primary rounded-circle"
        >
          <i className="fas fa-download"></i>
        </button>
      </div>

      <div className="pagination justify-content-center my-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-left"></i>
        </button>
        <h6 className="mx-2 my-auto">{currentPage}</h6>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === pageNo?.pages}
          className="btn btn-secondary"
        >
          <i className="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  )
}

export default Withdraw
